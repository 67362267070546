body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apx-legend-position-bottom {
  display: block !important;
}

@media print
{    
  body {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */  
  }

  .no-print, .no-print * {
      display: none !important;
  }
}

@page {
  /* margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0; */
  size: A4 portrait;
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important;                 /* Firefox 48 – 96 */
  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */  
}